import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import {
  Box,
  Collapse,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as InputIcon } from "../assets/icons/input.svg";
import { ReactComponent as LaporanIcon } from "../assets/icons/laporan.svg";
import { ReactComponent as EventIcon } from "../assets/icons/event.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/setting.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as UserIcon } from "../assets/icons/acc.svg";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  width: 240,
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  bottom: 0,
}));

const menu = [
  {
    icon: <DashboardIcon />,
    title: "Dashboard",
    link: "/",
    items: [],
  },
  {
    icon: <InputIcon />,
    link: "",
    title: "Input",
    items: [
      {
        title: "Pendapatan",
        items: [],
        link: "/input/1",
      },
      {
        title: "Pengeluaran",
        items: [],
        link: "/input/2",
      },
      // {
      //   title: "Event",
      //   items: [],
      //   link: "/input/event",
      // },
    ],
  },
  {
    icon: <LaporanIcon />,
    link: "",
    title: "Rekap Laporan",
    items: [
      {
        title: "Bulanan",
        items: [],
        link: "/laporan-bulanan",
      },
      {
        title: "Tahunan",
        items: [],
        link: "/laporan-tahunan",
      },
    ],
  },
  // {
  //   icon: <EventIcon />,
  //   title: "Rekap Event",
  //   link: '/laporan-event',
  //   items: [],
  // },
  {
    icon: <SettingIcon />,
    title: "Kelola Akun",
    link: "/pengaturan-akun",
    items: [],
  },
  {
    icon: <UserIcon />,
    title: "User",
    link: "/users",
    items: [],
  },
];

const Sidebar = ({ drawerWidth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const [open, setOpen] = React.useState({});
  const location = useLocation();
  const [select, setSelect] = useState(location.pathname);
  const [muiIconContent, setMuiIconContent] = useState();

  const handleClick = (index) => {
    setOpen({ ...open, [index]: !open[index] });
    window.scrollTo({ top: 0 });
  };

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/login");
  };

  const svg = `url("data:image/svg+xml,${encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="21" height="57" viewBox="0 0 21 57" fill="none">
  <mask id="mask0_50_3943" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="-27" width="21" height="107">
    <rect y="-26.3109" width="20.7414" height="106.296" fill="#C4C4C4"/>
  </mask>
  <g mask="url(#mask0_50_3943)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00143147 -13.8864C-0.00143051 -16.1774 -1.85868 -18.0347 -4.14971 -18.0347C-6.44074 -18.0347 -8.29799 -16.1774 -8.29799 -13.8864V8.61876C-8.29799 14.9135 -12.4463 20.9305 -12.4463 27.2252C-12.4463 33.5199 -8.29799 39.5369 -8.29799 45.8316V72.2161C-8.29799 74.5071 -6.44074 76.3644 -4.14971 76.3644C-1.85868 76.3644 -0.00143051 74.5071 -0.00143147 72.2161L-0.00143719 57.6139C-0.001441 47.2583 12.4434 37.5808 12.4434 27.2252C12.4434 26.509 12.3742 25.8078 12.2418 25.1273C10.4678 16.014 -0.001441 8.0605 -0.00143719 -1.22385L-0.00143147 -13.8864Z" fill="#7B57E0"/>
  </g>
</svg>`)} ")`;

  useEffect(() => {
    const newOpen = {};
    menu.forEach((menuItem, index) => {
      if (
        menuItem.link === location.pathname ||
        menuItem.items.some((subItem) => subItem.link === location.pathname)
      ) {
        newOpen[index] = true;
      }
    });
    setOpen(newOpen);
  }, [location]);

  const itemLogos = [
    {
      img: "/logos/logo_ipbi.png",
      title: "IPB Internasional",
    },
    {
      img: "/logos/logo_kotak_roamin.png",
      title: "Roamin",
    },
    {
      img: "/logos/logo_balichili.png",
      title: "The Balichili",
    },
  ];

  return (
    <>
      <List>
        {menu.map((menuItem, index) => (
          <div key={index}>
            {user && user.role === "user" && menuItem.title !== "User" && (
              <ListItem
                button
                onClick={() => handleClick(index)}
                component={menuItem.items.length ? "li" : NavLink}
                to={menuItem.link}
                sx={{
                  "&.active": {
                    background: "#F3F2FC",
                    "&::before": {
                      content: svg,
                      position: "absolute",
                      top: "0",
                      left: "0",
                    },
                  },
                }}
              >
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
                {menuItem.items.length ? (
                  open[index] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : null}
              </ListItem>
            )}
            {user && (user.role === "admin" || user.role === "manager") && (
              <ListItem
                button
                onClick={() => handleClick(index)}
                component={menuItem.items.length ? "li" : NavLink}
                to={menuItem.link}
                sx={{
                  "&.active": {
                    background: "#F3F2FC",
                    "&::before": {
                      content: svg,
                      position: "absolute",
                      top: "0",
                      left: "0",
                    },
                  },
                }}
              >
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
                {menuItem.items.length ? (
                  open[index] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                ) : null}
              </ListItem>
            )}
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuItem.items.map((subItem, subIndex) => (
                  <ListItem
                    button
                    key={subIndex}
                    component={NavLink}
                    to={subItem.link}
                    sx={{
                      "&.active": {
                        background: "#F3F2FC",
                        "&::before": {
                          content: svg,
                          position: "absolute",
                          top: "0",
                          left: "0",
                        },
                      },
                    }}
                  >
                    <ListItemText inset primary={subItem.title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </List>

      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
          alignItems: "center",
        }}
      >
        <Typography
          color="black"
          variant="subtitle1"
          fontWeight={600}
          textAlign={"center"}
        >
          Support By:
        </Typography>
        <ImageList cols={1} gap={10}>
          {itemLogos.map((item, index) => (
            <ImageListItem key={index}>
              <Box
                component="img"
                sx={{
                  width: "120px",
                  height: "auto",
                }}
                alt={item.title}
                src={item.img}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box> */}

      <DrawerFooter>
        <ListItem>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </DrawerFooter>
    </>
  );
};

export default Sidebar;
